<template>
  <v-col cols="7">
    <v-text-field :label="parameter.display_name"
      v-model="textValue" outlined dense background-color="white"
      :prepend-inner-icon="(parameter.data_type === 'date') ? 'mdi-calendar-outline' : ''"
      :hint="(parameter.data_type === 'date') ? 'M/DD/YYYY' : ''" @change="addParameterValue">
    </v-text-field>
  </v-col>
</template>

<script>
export default {
  name: 'InputText',

  data () {
    return {
      textValue: ''
    }
  },

  props: ['parameter'],

  methods: {
    addParameterValue () {
      this.$emit('add', {
        parameterName: this.parameter.name,
        parameterValue: this.parameter.data_type === 'date' ? this.moment(this.textValue).format(this.$config.date_format) : this.textValue
      })
    }
  }
}
</script>
